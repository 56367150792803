import React, { useState, useEffect, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import Layout from '../components/layout'
import '../styles/admccoursefb.css'
import { Accordion, Spinner } from 'react-bootstrap'
import {quizkyCrampeteAPI} from '../config'
import {getCookie} from '../utils/Helper'

const Admccoursefb = () => {

    const queryString = global.window?.location.search
    const urlParams = new URLSearchParams(queryString)

    const [timerDays, setTimerDays] = useState('00')
    const [timerHours, setTimerHours] = useState('00')
    const [timerMinutes, setTimerMinutes] = useState('00')
    const [timerSeconds, setTimerSeconds] = useState('00')

    let interval = useRef();

    const startTimer = () => {

        let cookie = getCookie('fswdCountDown');
        let timer;
        if(cookie) {
            timer = parseInt(cookie);
        } else {
            let result = new Date();
            let cookieDate = result.setDate(result.getDate() + 2);
            document.cookie = `fswdCountDown=${cookieDate}; max-age=172800; path=/;`;
            timer = cookieDate;
        }

        let countDownDate = new Date(timer).getTime();
        
        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if(distance < 0) {
                //reset timer
                let result = new Date();
                let cookieDate = result.setDate(result.getDate() + 2);
                document.cookie = `fswdCountDown=${cookieDate}; max-age=172800; path=/;`;
                // clearInterval(interval.current);
            } else {
                // update timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        }, 1000);

    }

    const [spinner, setSpinner] = useState(false);

    const [formFields, setFormFields] = useState({
        name: '',
        email: '',
        number: '',
        city: '',
        course: '',
        isAgree: false
    });

    const [utmParams] = useState({
        utmSource: urlParams.get("utm_source") || "",
    })
    let utmSource = utmParams.utmSource ? utmParams.utmSource : 'organic';
    const url = global.window?.location.href;
    const [formErrors, setFormErrors] = useState("")

    const [success, setSuccess] = useState("");

    const handleChange = (e) => {

        let value = e.target.value;

        if (e.target.name === 'name') {
            setFormFields((prevState) => {
                return { ...prevState, name: value }
            })
        } else if (e.target.name === 'email') {
            setFormFields((prevState) => {
                return { ...prevState, email: value }
            })
        } else if (e.target.name === 'number') {
            setFormFields((prevState) => {
                return { ...prevState, number: value }
            })
        } else if(e.target.name === 'city'){
            setFormFields((prevState) => {
                return { ...prevState, city: value }
            })
        } else if(e.target.name === 'course'){
            setFormFields((prevState) => {
                return { ...prevState, course: value }
            })
        } else if (e.target.name === 'isAgree') {
            let value = e.target.checked;
            setFormFields((prevState) => {
                return { ...prevState, isAgree: value }
            })
        }
        setFormErrors("");
        setSuccess("");
    }

    const handleSubmit = () => {
        if (!formFields.name) {
            setFormErrors("Please enter your name");
        } else if ((!formFields.email) || !(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/.test(formFields.email))) {
            setFormErrors("Please enter a valid email");
        } else if (!formFields.number) {
            setFormErrors("Please enter your number");
        } else if(!formFields.city) {
            setFormErrors("Please enter your city");
        } else if(!formFields.course) {
            setFormErrors("Please enter your course");
        }
        else if (!formFields.isAgree) {
            setFormErrors("You must agree before submitting");
        } else {

            setSpinner(true);

            const opts = {
                "companyCode": "crampete",
                "accountId": "master",
                "studentName": formFields.name,
                "emailId": formFields.email,
                "mobileNumber": formFields.number,
                "eventId": "",
                "source": "website",
                "city": formFields.city,
                "referenceCourseName": formFields.course,
                "source": utmSource,
                "url": url
            }
            setTimeout(() => {
                fetch(`${quizkyCrampeteAPI}/common-lead/zoho` , {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(opts)
                }).then(function (response) {
                    return response.json();
                }).then(function (res) {
                    if (res.success) {
                        setFormFields({
                            name: ' ',
                            email: ' ',
                            number: ' ',
                            city: ' ',
                            course: ' ',
                            isAgree: true
                        })
                        // setSuccess("Thank you, we\'ve received your details.");
                        navigate('/thank-you/')
                    } else {
                        setSuccess("Something went wrong, please try again!");
                    }

                }).catch((e) => console.error('Error:', e));
                
                setSpinner(false);
            }, 1000);
            }
    }

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        }
    }, [])

    return (
        <Layout isLanding={true}>
            <main className="admc-main">
                <section className="admc-banner-section">
                    <div className="container">
                        <div className="admc-banner-content">
                            <h1>Full Stack Web <span>Development Program</span></h1>
                            <p>Increase your chances of getting an IT job from a top employer</p>
                            <p>Perfect for beginners • Extensive job assistance • One-to-one mentoring • Online live course • Weekday / Weekend batches • Flexible EMIs</p>
                            <Link to="/full-stack-web-dev-online-live-training/#form-col">Enquire Now</Link>
                        </div>
                    </div>
                </section>
                <section className="admc-know-more-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="know-more-wrapper">
                                    <div className="admc-cant-wait">
                                        <img src={require('../../static/images/admccoursefb/more-about-course-icon.png')} alt="Know more about the course" />
                                        <div>
                                            <h4>Can’t Wait to Know More about the Course?</h4>
                                            <p>We are always here to assist you. Just submit your contact details and get a call back from our career counselors at the earliest.</p>
                                        </div>
                                    </div>
                                    <div className="know-more-img-wrapper">
                                        <img src={require('../../static/images/admccoursefb/know-more.jpg')} alt="Know More" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" id="form-col">
                                <div className="form-wrapper">
                                    <p className="application-closes-in">APPLICATION CLOSES IN</p>
                                        <ul id="count-down">
                                            <li className="circle days">{timerDays} :</li>
                                            <li className="circle hours">{timerHours} :</li>
                                            <li className="circle minutes">{timerMinutes} :</li>
                                            <li className="circle seconds">{timerSeconds}</li>
                                        </ul>
                                    <div className="d-flex justify-content-between justify-content-center mb-3 font-weight-bold">
                                        <div>DAYS</div>
                                        <div>-</div>
                                        <div>HRS</div>
                                        <div>-</div>
                                        <div>MINS</div>
                                        <div>-</div>
                                        <div>SECS</div>
                                    </div>
                                    <h3 className="mb-3">Get Program Brochure</h3>
                                    <div className="form-group">
                                        <input type="text" className="form-control" aria-describedby="Full Name" name="name" placeholder="Full Name" onChange={handleChange} autoComplete="off" />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" aria-describedby="Email Address" name="email" placeholder="Email Address" onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <input type="number" className="form-control" aria-describedby="Mobile Number" name="number" placeholder="Mobile Number" onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" aria-describedby="City" name="city" placeholder="City" onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <select name="course" value={formFields.course} onChange={handleChange} className="form-control">
                                            <option>-- Select Course --</option>
                                            <option key="99ff790f15114c59b19d7348c2c3d8d4" value="Data Science">Data Science</option>
                                            <option key="0e0fb9076212290646b53966d9250893" value="Full Stack Development">Full Stack Development</option>
                                            <option key="71096c4950f108810ca5a328c00cae2a" value="Digital Marketing">Digital Marketing</option>
                                        </select>
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" name="isAgree" htmlFor="isAgree" checked={formFields.isAgree} onChange={handleChange} id="isAgree" />
                                        <label className="form-check-label" htmlFor="isAgree">I agree to receive information</label>
                                    </div>
                                    {!spinner ? <button onClick={handleSubmit}>Submit</button> : <button><Spinner as="span" animation="border" variant="light" size="sm" aria-hidden="true" />Please Wait...</button> }
                                    
                                    <div className="message">
                                        {formErrors && <div id="errors">{formErrors}</div>}
                                        {success && <div id="result">{success}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="admc-key-highlights-section">
                    <div className="container">
                        <div className="key-highlights-wrapper">
                            {/* <div className="text-center">
                                <h3>KEY HIGHLIGHTS</h3>
                                <div className="key-highlights-line"></div>
                            </div> */}
                            <h1 className="title-h1">Key Highlights</h1>
                            <div className="admc-underline"></div>
                            
                            <div className="highlights-wrapper">
                                <div className="row">
                                    <div className="col-6 col-md-2">
                                        <div className="highlight-wrapper">
                                            <img src={require('../../static/images/admccoursefb/5-live-projects.png')} alt="5+ Live projects" />
                                            <h4>5+ Live projects</h4>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                        <div className="highlight-wrapper">
                                            <img src={require('../../static/images/admccoursefb/helps-launch-or-switch-career.png')} alt="Helps launch or switch careers" />
                                            <h4>Helps launch or switch careers</h4>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                        <div className="highlight-wrapper">
                                            <img src={require('../../static/images/admccoursefb/one-to-one-mentoring.png')} alt="One-to-one mentoring" />
                                            <h4>One-to-one mentoring</h4>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                        <div className="highlight-wrapper">
                                            <img src={require('../../static/images/admccoursefb/basic-to-advance-skills.png')} alt="Basic to advanced skills" />
                                            <h4>Basic to advanced skills</h4>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                        <div className="highlight-wrapper">
                                            <img src={require('../../static/images/admccoursefb/jobs-&-interview-assistance.png')} alt="Job &amp; interview assistance" />
                                            <h4>Job &amp; interview assistance</h4>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                        <div className="highlight-wrapper">
                                            <img src={require('../../static/images/admccoursefb/pay-in-EMIs.png')} alt="Pay in EMIs" />
                                            <h4>Pay in EMIs</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="admc-section-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="section-4-content-wrapper">
                                    <h1>About Full Stack Web Development Certification</h1>
                                    <p>Full stack web development is the most in-demand software job in the market. This course, designed for both students with and without coding experience, makes you a well-rounded developer, ready to be hired by top MNCs.</p>
                                    <p>The curriculum and the teaching methodology of this course are regularly updated based on the feedback from software leaders and alumni students. When you enroll in this course, you get the most in-demand skills from trainers who are dedicated to your success.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="section-4-image-wrapper">
                                    <img src={require('../../static/images/admccoursefb/section-4-left.png')} alt="Full Stack Web Development Online Live Training" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="scope-section">
                    <div className="container">
                        <h1 className="title-h1">Careers after full-stack certification</h1>
                        <div className="admc-underline"></div>
                        <div className="admc-careers-wrapper">
                            <div className="row">
                                <div className="col-md-8">
                                    <ul className="admc-careers">
                                        <li>Full-Stack Developer</li>
                                        <li>Back End Developer</li>
                                        <li>Front-End Developer</li>
                                        <li>Web Developer</li>
                                        <li>Web Designer</li>
                                        <li>React Developer</li>
                                        <li>UI Developer</li>
                                        <li>Node.js Developer</li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <div className="admc-salary">
                                        <p>Industry average salary for roles in full-stack developer</p>
                                        <img src={require('../../static/images/admccoursefb/salary.png')} alt="Salary" />
                                        <img src={require('../../static/images/admccoursefb/glassdoor.png')} alt="Glassdoor" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="why-choose-section">
                    <div className="container">
                        <h1 className="title-h1">Course Features</h1>
                        <div className="admc-underline-white"></div>
                        <div className="why-choose-wrapper-outer">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="why-choose-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/become-in-demand.png')} alt="Become In-Demand" />
                                        <h3>Become In-Demand</h3>
                                        <p>Learning to code will help you realign your skills to meet current and upcoming job market trends.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="why-choose-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/updated-curriculum.png')} alt="Updated Curriculum" />
                                        <h3>Updated Curriculum</h3>
                                        <p>We constantly upgrade the course curriculum to give you a clear edge in the job market.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="why-choose-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/learn-by-doing.png')} alt="Learn By Doing" />
                                        <h3>Learn By Doing</h3>
                                        <p>This program really helps you to get a holistic understanding of a tech product by building several features on your own.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="why-choose-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/doubt-clarification.png')} alt="Doubt Clarifications" />
                                        <h3>Doubt Clarifications</h3>
                                        <p>You'll be surprised by how fast your mentor answers your questions and how supportive they are.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="why-choose-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/build-strong-portfolio.png')} alt="Build a Strong Portfolio" />
                                        <h3>Build a Strong Portfolio</h3>
                                        <p>There are many projects to help you showcase your new skills convincingly to your future employer.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="why-choose-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/completely-online.png')} alt="Completely Online" />
                                        <h3>Completely Online</h3>
                                        <p>You don’t have to travel to get the benefits from this course. Learn with others in a virtual classroom setting.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="who-can-learn-section">
                    <div className="container">
                        <h1 className="title-h1">Why People Join Our Course</h1>
                        <div className="admc-underline"></div>
                        <div className="who-can-wrapper-outer">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="who-can-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/get-1st-IT-job.png')} alt="To get a first IT job" />
                                        <h4>To get a first IT job</h4>
                                        <p>Since the material is suited for freshers, they can learn the full stack skills and quickly get their first IT job.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="who-can-wrapper-inner center">
                                        <img src={require('../../static/images/admccoursefb/career-switch.png')} alt="To make a career switch" />
                                        <h4>To make a career switch</h4>
                                        <p>Professionals from non-technical backgrounds find our full-stack training makes it easy to get an IT job, real fast.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="who-can-wrapper-inner right">
                                        <img src={require('../../static/images/admccoursefb/work-on-their-own.png')} alt="To work on their own" />
                                        <h4>To work on their own</h4>
                                        <p>The demand for full-stack developers is steady. Anyone can make a good living as a freelancer or a web consultant.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="program-curriculum-section">
                    <div className="container">
                        <h1 className="title-h1">Program Curriculum</h1>
                        <div className="admc-underline"></div>
                        <div className="program-curriculum-outer-wrapper">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="program-curriculum-inner-wrapper">
                                        <h4>Module 1:</h4>
                                        <p>A Strong foundation on HTML</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="program-curriculum-inner-wrapper">
                                        <h4>Module 2:</h4>
                                        <p>Introduction &amp; application of CSS  to HTML</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="program-curriculum-inner-wrapper">
                                        <h4>Module 3:</h4>
                                        <p>Start to Build portfolio and websites</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="program-curriculum-inner-wrapper">
                                        <h4>Module 4:</h4>
                                        <p>Build interactive web pages by using Javascript</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="program-curriculum-inner-wrapper">
                                        <h4>Module 5:</h4>
                                        <p>Build Front end application by using ReactJS</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="program-curriculum-inner-wrapper">
                                        <h4>Module 6:</h4>
                                        <p>How to use Node js for server side scripting</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="program-curriculum-inner-wrapper">
                                        <h4>Module 7:</h4>
                                        <p>Migration of Data into MongoDB</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="program-curriculum-inner-wrapper">
                                        <h4>Module 8:</h4>
                                        <p>Use of version Control Software</p>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <Link to="/full-stack-web-dev-online-live-training/#form-col">DOWNLOAD DETAILED CURRICULUM</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tools-section">
                    <div className="container">
                        <h1 className="title-h1">Languages / Tools you will learn</h1>
                        <div className="admc-underline"></div>
                        <div className="tools-wrapper-outer">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="tools-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/html.png')} alt="HTML" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="tools-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/css.png')} alt="CSS" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="tools-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/bootstrap.png')} alt="Bootstrap" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="tools-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/js.png')} alt="Javascript" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="tools-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/react.png')} alt="ReactJS" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="tools-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/mongodb.png')} alt="MongoDB" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="tools-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/node-js.png')} alt="NodeJS" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="tools-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/github.png')} alt="Github" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="live-projects-section">
                    <div className="container">
                        <h1 className="title-h1">Live Projects &amp; Mock Interviews</h1>
                        <div className="admc-underline-white"></div>
                        <div className="live-projects-wrapper-outer">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="live-projects-wrapper-inner text-center">
                                        <img src={require('../../static/images/admccoursefb/live-projects.png')} alt="Live Projects" />
                                        <h3>Live Projects</h3>
                                        <p>You can't learn programming just listening to lectures. That's why we have a battery of exciting projects that will deepen and solidify your theoretical and conceptual understanding. Go through them and in the end you'll have the confidence to take on any project that comes your way.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="live-projects-wrapper-inner text-center second">
                                        <img src={require('../../static/images/admccoursefb/mock-interviews.png')} alt="Mock Interviews" />
                                        <h3>Mock Interviews</h3>
                                        <p>What's skill if you can't convincingly present it to a prospective employer and get hired? To give you real-world experience in interviewing, this course includes mock technical interviews with developers having 5+ years of experience. Your practice with them will get ready for the toughest job interviews you'll face.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="program-fee-section">
                    <div className="container">
                        <h1 className="title-h1">Important Information</h1>
                        <div className="admc-underline"></div>
                        <div className="row mt-5 curriculum">
                            <div className="col-md-3">
                                <div className="program-curriculum-inner-wrapper">
                                    <h4>Duration:</h4>
                                    <p>6 months</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="program-curriculum-inner-wrapper">
                                    <h4>Format:</h4>
                                    <p>Live online classes</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="program-curriculum-inner-wrapper">
                                    <h4>Course starts:</h4>
                                    <p>January, 2021</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="program-curriculum-inner-wrapper">
                                    <h4>Days:</h4>
                                    <p>Weekend Batches</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="career-support-section">
                    <div className="container">
                        <h1 className="title-h1">Career Support</h1>
                        <div className="admc-underline"></div>
                        <div className="career-support-wrapper-outer text-center">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="career-support-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/resumer-building.jpg')} alt="Resume Building Support" />
                                        <h3>Resume Building Support</h3>
                                        <p>Once you complete the course, we’ll have a professional resume writer create a compelling resume for you.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="career-support-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/mock-interview.jpg')} alt="Mock Interviews" />
                                        <h3>Mock Interviews</h3>
                                        <p>We offer one-to-one mock interview sessions with our trainers to help you prepare well.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="career-support-wrapper-inner">
                                        <img src={require('../../static/images/admccoursefb/interview-references.jpg')} alt="Interview References" />
                                        <h3>Interview References</h3>
                                        <p>We not only prepare you for interviews, we also present you three exciting interview opportunities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="students-work-section">
                    <div className="container">
                        <h1 className="title-h1">Our Students Work In</h1>
                        <div className="admc-underline"></div>
                        <p className="description">We’ve partnered with a plethora of top companies spanning various sectors and industries. Our students have received job offers in diverse roles from our 1000+ recruitment partners.</p>
                        <ul className="companies">
                            <li><img src={require('../../static/images/admccoursefb/accenture.png')} alt="Accenture" /></li>
                            <li><img src={require('../../static/images/admccoursefb/amazon.png')} alt="Amazon" /></li>
                            <li><img src={require('../../static/images/admccoursefb/ford.png')} alt="Ford" /></li>
                            <li><img src={require('../../static/images/admccoursefb/hexaware.png')} alt="Hexaware" /></li>
                            <li><img src={require('../../static/images/admccoursefb/microsoft.png')} alt="Microsoft" /></li>
                            <li><img src={require('../../static/images/admccoursefb/sulekha.png')} alt="Sulekha" /></li>
                            <li><img src={require('../../static/images/admccoursefb/tata.png')} alt="Tata" /></li>
                            <li><img src={require('../../static/images/admccoursefb/landt.png')} alt="L &amp; T" /></li>
                            <li><img src={require('../../static/images/admccoursefb/ntt.png')} alt="NTT Data" /></li>
                        </ul>
                        <div className="text-center">
                            <Link to="/full-stack-web-dev-online-live-training/#form-col">DOWNLOAD DETAILED CURRICULUM</Link>
                        </div>
                    </div>
                </section>
                <section className="faq-section">
                    <div className="container">
                        <h1 className="title-h1">Frequently Asked Question</h1>
                        <div className="admc-underline"></div>
                        <div className="accordion-wrapper">
                            <Accordion>
                                <Accordion.Toggle variant="link" eventKey="0" className="odd">What is the eligibility criteria to join this course?<span className="ti-plus accIcon"></span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <div className="accContent">
                                        <p itemProp="text">Crampete's Full Stack Web Development Course requires no prior technical skills. You just need to motivated and willing to learn a variety of exciting tech skills that we teach.</p>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                            <Accordion>
                                <Accordion.Toggle variant="link" eventKey="1" className="odd">Do I need to have any coding skills?<span className="ti-plus accIcon"></span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <div className="accContent">
                                        <p itemProp="text">This course is designed for the absolute beginner without any coding skills. However, if you already have some basic skills, you'll breeze through a few lessons.</p>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                            <Accordion>
                                <Accordion.Toggle variant="link" eventKey="2" className="odd">How many hours should I expect to invest in this course?<span className="ti-plus accIcon"></span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <div className="accContent">
                                        <p itemProp="text">Data from past students show that they usually spend six hours a week to complete the homework assignments.</p>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default Admccoursefb
